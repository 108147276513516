import { useTranslation } from 'next-i18next';
import React from 'react';
import { Box, Container, ImageX } from '@bridebook/ui';
import componentStyles from 'lib/error-styles';
import Head from '../components/app/head';

interface IProps {
  statusCode: number;
}

const ErrorComp = ({ statusCode }: IProps) => {
  const { t } = useTranslation('common');

  const title = t('errorPage.title') + (statusCode === 404 ? '' : t('errorPage.refresh'));

  const styles = componentStyles();

  return (
    <Container>
      <Head title={t('errorPage.pageTitle')} />
      <Box style={styles.wrapper}>
        <Box as="h1" style={styles.heading}>
          {title}
        </Box>
        <Box style={styles.imgContainer}>
          <ImageX
            src="//bridebook.imgix.net/assets/404-not-found.jpg?auto=compress"
            altText={t('errorPage.notFound')}
            w={660}
            h={440}
          />
          <Box style={styles.imgCaption}>{t('errorPage.imageCaption') as string}</Box>
        </Box>
        <Box style={styles.statusMsg}>
          {
            (statusCode
              ? t('errorPage.errorOnServer', {
                  statusCode,
                })
              : t('errorPage.errorOnClient')) as string
          }
        </Box>
      </Box>
    </Container>
  );
};

export default ErrorComp;
