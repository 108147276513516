import Head from 'next/head';
import React, { FunctionComponent } from 'react';
import { env } from '@bridebook/toolbox/src/env';

interface IProps {
  title: string;
  description?: string | null;
  canonical?: string | null;
  ogTitle?: string | null;
  ogDescription?: string | null;
  ogImage?: string | null;
  ogImageWidth?: string | null;
  ogImageHeight?: string | null;
  ogType?: string | null;
  ogUrl?: string | null;
  robots?: string | null;
  plainTitle?: boolean;
  plainOgTitle?: boolean;
}

const defaultMeta = {
  description:
    'Plan your dream wedding with Bridebook - your personal wedding planner. Find wedding venues, photographers, inspiration, tools and more, all in one place.',
  'og:decription': 'Plan your dream wedding with Bridebook - Your Free Online Wedding Planner',
  'og:title': 'Bridebook - Your Free Online Wedding Planner',
  'og:image': 'https://bridebook.imgix.net/assets/social/og-image-v1.png?auto=compress,format',
  'og:image:width': '1200',
  'og:image:height': '630',
  'og:type': 'website',
};

const isLive = env.LIVE;

const PageHead: FunctionComponent<IProps> = ({
  title,
  description,
  canonical,
  ogTitle,
  ogDescription,
  ogImage,
  ogImageWidth,
  ogImageHeight,
  ogType,
  ogUrl,
  robots,
  children,
  plainTitle = false,
  plainOgTitle = false,
}) => {
  const getTitle = (title?: string | null) => {
    if (title) {
      return plainTitle ? title : `${String(title)} | Bridebook`;
    }
    return defaultMeta['og:title'];
  };
  const pageTitle = plainTitle ? title : getTitle(title);
  const pageDescription = description || description === '' ? description : defaultMeta.description;
  const pageOgDescription = ogDescription || defaultMeta['og:decription'];
  const pageOgImage = ogImage || defaultMeta['og:image'];
  const pageOgImageWidth = ogImageWidth || defaultMeta['og:image:width'];
  const pageOgImageHeight = ogImageHeight || defaultMeta['og:image:height'];
  const pageOgTitle = ogTitle && plainOgTitle ? ogTitle : getTitle(ogTitle);
  const pageOgType = ogType || defaultMeta['og:type'];
  const pageOgUrl = ogUrl || canonical;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      {isLive && robots && <meta name="robots" content={robots} />}
      <meta property="og:title" content={pageOgTitle} />
      <meta property="og:description" content={pageOgDescription} />
      <meta property="og:type" content={pageOgType} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:image" content={pageOgImage} />
      <meta property="og:image:width" content={pageOgImageWidth} />
      <meta property="og:image:height" content={pageOgImageHeight} />
      {/* FIXME: uncomment line below */}
      {/*{canonical && isLiveOnCDN && <link rel="canonical" href={canonical} />}*/}
      {/* FIXME: remove line below before merge - debugging only */}
      {canonical && <link rel="canonical" href={canonical} />}
      {pageOgUrl && <meta property="og:url" content={pageOgUrl} />}
      <meta property="og:site_name" content="Bridebook" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@bridebook" />
      <meta name="p:domain_verify" content="797eb482a41518e7a6dd4bd7f80c1297" />
      <meta name="theme-color" content="#4D52E3" />
      <meta name="referrer" content="always" />
      <meta
        content="viewport-fit=cover, width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        name="viewport"
      />
      {children}
    </Head>
  );
};

export default PageHead;
